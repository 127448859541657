<template>
  <div class="bg-dark text-white pb-20" v-editable="blok">
    <div class="container">
      <template v-if="publicEvents?.length">
        <div class="pt-5">
          <div class="flex flex-wrap items-center pt-4 pb-3 mb-3 border-b-1 border-b-white/25">
            <h2 class="text-5xl lg:text-6xl font-secondary">
              {{ translate('heading.upcomingEvents') }}
              <span v-if="publicEvents.length > 1">({{ publicEvents.length }})</span>
            </h2>
            <VenueAmenities
              v-if="venue?.content?.facilities"
              class="max-lg:hidden"
              :amenities="venue?.content?.facilities"
            />
          </div>
          <VenueAmenities
            v-if="venue?.content?.facilities"
            class="lg:hidden mb-2"
            :amenities="venue?.content?.facilities"
          />
          <DetailList
            v-if="details"
            :details="details"
            :venue="venue?.name"
            :slug="venue?.full_slug"
            background="dark"
          />

          <div ref="mainCarouselContainer">
            <MxCarousel
              ref="mainCarousel"
              class="mt-4"
              :items="publicEvents"
              :configuration="{
                arrows: publicEvents.length > 1,
                gap: 20,
                pagination: false,
                perPage: ticketCarouselLength, // This shows a bit of the next ticket
                focus: 'start',
                type: 'slide',
                rewind: false,
                flickMaxPages: 1,
                slideFocus: true,
                snap: true,
                moveBy: 1, // This ensures it moves only one slide at a time
                omitEnd: true,
                trimSpace: true,
                end: (Splide: any) => {
                  return Math.max(0, Splide.length - ticketCarouselLength); // Stop at length - 4 position
                },
              }"
              arrowPosition="top"
              @splide:mounted="onMainCarouselMounted"
            >
              <!--Active events-->
              <MxCarouselSlide
                v-for="event in publicEvents"
                class="p-6 bg-light rounded-md text-black flex flex-col"
                :key="event.content.SyncId"
              >
                <AvailabilityLabel
                  :id="event.content.SyncId"
                  type="Event"
                  class="inline-flex absolute w-fit mt-2 left-auto right-0 !-top-2 rounded-bl-md rounded-tr-md"
                />
                <h3 class="text-3xl text-grey font-secondary word-break" :lang="language">
                  {{ event.name || event.content.Name || event.content.SyncName }}
                </h3>
                <h3 class="text-3xl font-medium">
                  {{
                    formatDate(addTimeZoneToSBDate(event.content.SyncEventStartTime), {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })
                  }}
                </h3>

                <div
                  class="flex flex-wrap mt-2 mb-4 py-3 gap-2 border-y border-solid border-black/25 sm:text-xl"
                >
                  <span
                    v-if="event?.content?.SyncVenue.content"
                    class="flex items-center gap-x-2 text-base font-semibold"
                  >
                    <Icon class="text-primary text-lg" name="LocationMarker" />

                    {{
                      event?.content?.SyncVenue.content?.Name ||
                      event?.content?.SyncVenue.content?.name ||
                      event?.content.SyncVenue.content?.SyncName
                    }}
                  </span>

                  <span class="flex items-center text-base font-semibold">
                    <Icon class="text-primary text-lg mr-2" name="Clock" />

                    <span v-if="language === 'no'" class="mr-1">Kl.</span>
                    {{
                      formatDate(addTimeZoneToSBDate(event?.content.SyncEventStartTime), {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    }}
                    -
                    {{
                      formatDate(addTimeZoneToSBDate(event?.content.SyncEventEndTime), {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    }}
                  </span>
                </div>

                <div
                  class="border-y border-y-white/25 flex flex-col xl:flex-row xl:space-x-4 mt-auto"
                >
                  <div class="flex gap-x-3">
                    <MxButton
                      v-if="availability.state.value.events[event.content.SyncId] === 2"
                      size="small"
                      class="text-lg font-medium !px-4 !h-fit !text-grey !border-grey"
                      layout="outline-on-dark"
                      disabled
                    >
                      {{ translate('availability.soldOut') }}
                      <Icon class="text-sm" name="Close" width="1.5" />
                    </MxButton>
                    <MxButton
                      v-else
                      :href="getStoryblokLink(event.content.SyncBookingLink) || undefined"
                      size="small"
                      class="text-lg font-medium !px-4"
                      :class="{
                        'bg-primary-light': isEventPricingFree(event.content.SyncPricing),
                      }"
                      :disabled="isEventPricingFree(event.content.SyncPricing)"
                    >
                      <template v-if="isEventPricingFree(event.content.SyncPricing)">
                        {{ translate('action.free') }}
                      </template>
                      <template v-else>
                        {{ translate('action.purchase') }}
                        <Icon class="text-xl" name="ArrowRight" width="1.5" />
                      </template>
                    </MxButton>

                    <template v-if="isLoggedIn">
                      <MxButton
                        v-if="favouritesState.favourites.includes(event.content.SyncId)"
                        class="text-3xl h-fit !py-2 !px-2 !bg-black !hover:bg-black z-10"
                        :class="{
                          'cursor-wait': favouritesState.isLoading,
                        }"
                        size="small"
                        :disabled="favouritesState.isLoading"
                        @click="() => removeFavourite(event.content.SyncId)"
                      >
                        <Icon name="Heart" class="text-primary" />
                      </MxButton>
                      <MxButton
                        v-else
                        class="text-3xl h-fit !py-2 !px-2 !bg-gray/20 !border-gray/40 !hover:bg-black z-10"
                        :class="{
                          'cursor-wait': favouritesState.isLoading,
                        }"
                        size="small"
                        :disabled="favouritesState.isLoading"
                        @click="() => addFavourite(event.content.SyncId)"
                      >
                        <Icon name="HeartOutline" />
                      </MxButton>
                    </template>
                    <div v-else class="relative z-20">
                      <MxButton
                        :href="auth.logInUrl.value"
                        class="text-3xl h-fit !py-2 !px-2 !bg-gray/20 !border-gray/40 !hover:bg-black !hover:border-primary"
                        size="small"
                        @mouseenter="event.showTooltip = true"
                        @mouseleave="event.showTooltip = false"
                      >
                        <Icon name="HeartOutline" />
                      </MxButton>
                      <div
                        v-if="event.showTooltip"
                        class="hidden md:block absolute bottom-full left-1/2 transform -translate-x-1/2 mb-3 px-3 pt-1.5 pb-2 bg-black text-white text-sm rounded whitespace-nowrap speech-bubble"
                      >
                        {{ loginTooltipText }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    event.content.SyncWaitingList &&
                    availability.state.value.events[event.content.SyncId] === 2
                  "
                  class="text-base mt-2 -mb-4 xl:mb-0"
                >
                  <p>
                    {{ translate('label.eventIsSoldOut') }}. {{ translate('label.putYourselfOn') }}
                    <NuxtLink
                      :href="getStoryblokLink(event.content.SyncBookingLink) || undefined"
                      class="text-primary"
                    >
                      {{ translate('label.theWaitingListHere') }} </NuxtLink
                    >.
                  </p>
                </div>
              </MxCarouselSlide>
              <!--Completed events-->
              <MxCarouselSlide
                v-for="event in completedEvents"
                class="p-6 bg-grey-dark rounded-md text-grey flex flex-col overflow-hidden"
                :key="event.content.SyncId"
              >
                <h3 class="text-3xl font-secondary">
                  {{ event.name || event.content.Name || event.content.SyncName }}
                </h3>
                <h3 class="text-3xl font-medium text-white/50 line-through">
                  {{
                    formatDate(addTimeZoneToSBDate(event.content.SyncEventStartTime), {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })
                  }}
                </h3>

                <div
                  class="flex flex-wrap mt-2 mb-4 py-3 gap-2 border-y border-solid border-black/25 sm:text-xl"
                >
                  <span
                    v-if="event?.content?.SyncVenue.content"
                    class="flex items-center gap-x-2 text-base font-semibold underline"
                  >
                    <Icon class="text-grey text-lg" name="LocationMarker" />

                    {{
                      event?.content?.SyncVenue.content?.Name ||
                      event?.content?.SyncVenue.content?.name ||
                      event?.content.SyncVenue.content?.SyncName
                    }}
                  </span>

                  <span class="flex items-center gap-x-2 text-base font-semibold">
                    <Icon class="text-grey text-lg" name="CheckmarkCircled" />

                    <span v-if="language === 'no'" class="mr-1">Kl.</span>
                    {{
                      formatDate(addTimeZoneToSBDate(event?.content.SyncEventStartTime), {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    }}
                    -
                    {{
                      formatDate(addTimeZoneToSBDate(event?.content.SyncEventEndTime), {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    }}
                  </span>
                </div>

                <AvailabilityLabel
                  :id="event.content.SyncId"
                  type="Event"
                  class="inline-flex absolute w-fit mt-2 left-auto right-0 -top-2 rounded-bl-md"
                />

                <div class="flex flex-col xl:flex-row xl:space-x-4 mt-auto">
                  <div class="flex gap-x-3">
                    <MxButton
                      size="small"
                      class="!px-4 !bg-white text-lg !text-grey font-semibold border-white"
                      layout="outline-on-dark"
                      disabled
                    >
                      {{ translate('label.eventFinished') }}
                    </MxButton>
                    <div class="relative z-20">
                      <MxButton
                        class="text-3xl !text-grey h-fit !py-2 !px-2 !bg-gray/20 !border-gray/40"
                        size="small"
                      >
                        <Icon name="HeartOutline" />
                      </MxButton>
                    </div>
                  </div>
                  <div
                    v-if="
                      event.content.SyncWaitingList &&
                      availability.state.value.events[event.content.SyncId] === 2
                    "
                    class="text-xl mt-4 -mb-4 xl:mt-0 xl:mb-0"
                  >
                    <p>
                      {{ translate('label.eventIsSoldOut') }}.
                      {{ translate('label.putYourselfOn') }}
                      <NuxtLink
                        :href="getStoryblokLink(event.content.SyncBookingLink) || undefined"
                        class="text-primary"
                      >
                        {{ translate('label.theWaitingListHere') }} </NuxtLink
                      >.
                    </p>
                  </div>
                </div>
              </MxCarouselSlide>
            </MxCarousel>
          </div>

          <SplideScrollbar
            v-if="mainSplideInstance && publicEvents.length > ticketCarouselLength"
            :mainSplide="mainSplideInstance"
            :totalItems="publicEvents.length"
            :visibleItems="ticketCarouselLength"
          />
        </div>

        <div v-if="ticketList?.length" class="mt-4 lg:mt-10">
          <div class="flex flex-row justify-between items-center pb-2 border-b-1 border-b-white/25">
            <h2 class="text-5xl lg:text-6xl font-secondary">
              {{ translate('heading.ticketPrices') }}
            </h2>

            <NuxtLink
              to="/billetter/billettinformasjon"
              class="hidden lg:block text-lg lg:text-xl font-medium text-primary underline"
              >{{ moreInformationLinkLabel }}</NuxtLink
            >
          </div>
          <ul
            ref="scrollContainerTicket"
            class="flex mt-6 max-lg:overflow-x-scroll lg:flex-wrap gap-4 lg:gap-6 :justify-start lg:justify-center scrollbar-hide"
          >
            <template v-for="ticket in ticketList" :key="ticket.PricingType">
              <li
                v-if="ticketHasPrice(ticket)"
                class="text-center text-white/70 flex flex-col justify-end"
              >
                <div
                  class="px-4 sm:px-10 py-3 relative text-xl lg:text-3xl border border-solid border-white/25 rounded-md min-w-fit"
                >
                  <h3
                    class="text-lg lg:text-xl after:content-[':'] text-grey font-medium mx-auto !leading-tight italic whitespace-nowrap"
                  >
                    {{ translate(ticket.PricingType) }}
                  </h3>
                  <p class="font-semibold text-white flex flex-row justify-center gap-1">
                    NOK
                    <span class="text-primaryBlue/Light">{{
                      formatPrices([ticket.PriceZoneC, ticket.PriceZoneB, ticket.PriceZoneA]).join(
                        '/',
                      )
                    }}</span>
                  </p>
                </div>
              </li>
            </template>
          </ul>
          <ScrollIndicator :container="scrollContainerTicket" class="lg:hidden" />
          <div class="mt-8">
            <NuxtLink
              to="/billetter/billettinformasjon"
              class="lg:hidden text-lg lg:text-xl font-medium text-primary underline"
              >{{ moreInformationLinkLabel }}</NuxtLink
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductionDetailsProps, ProductionProps, TicketProps } from '~/types/Production';
import { getStoryblokLink } from '~/composables/storyblokLink';
import { customerIoAPI } from '~/composables/customerIoApi';
import { useAuth } from '~/composables/useAuth';
import { useFavourites } from '~/composables/useFavourites';
import { useAvailability } from '~/composables/useAvailability';
import { addTimeZoneToSBDate, formatDate } from '~/utils/formatDate';
import { useTranslation } from '~/composables/useTranslation';
import { isEventPricingFree } from '~/composables/pricing';
import AvailabilityLabel from '~/components/AvailabilityLabel.vue';
import type { ISbStoryData } from 'storyblok-js-client';
import { ref, onMounted } from 'vue';

const { blok, loginTooltipText = 'Logg inn for å legge til i favoritter' } = defineProps<{
  blok: ProductionDetailsProps;
  loginTooltipText?: string;
}>();

const {
  eventList,
  venue,
  duration,
  moreInformationLinkLabel = 'Les mer om våre priser og rabatter',
} = blok;

const translate = useTranslation();
const availability = useAvailability();
const story = useState<ISbStoryData<ProductionProps>>('story');
const language = useState<string>('language');

const scrollContainerTicket = ref<HTMLElement | null>(null);
const scrollContainerShows = ref<HTMLElement | null>(null);

const mainProductionCategory = story.value.content?.Categories?.[0];

const showTooltip = ref(false);

let completedEvents = ref([]);

//Splide refs
const mainCarousel = ref<HTMLElement | null>(null);
const mainSplideInstance = ref<any>(null);

const onMainCarouselMounted = (splide) => {
  console.log('Splide instance received:', splide);
  mainSplideInstance.value = splide;
};

const publicEvents = computed(() => {
  const now = new Date();
  try {
    return eventList?.filter((event) => {
      // Skip private events
      if (event.content?.SyncPrivateEvent) {
        return false;
      }
      //Skip events that have already begun or finished
      const zonedEventDate = new Date(addTimeZoneToSBDate(event.content?.SyncEventStartTime));
      if (now > zonedEventDate) {
        completedEvents.value.push(event);
        return false;
      }

      event.showTooltip = false;

      return true;
    });
  } catch (err) {
    console.error('ProductionDetails: Failed to read public event list');
    console.error(err);
    return [];
  }
});

const primeEvent = publicEvents.value?.[0];
const venueString =
  venue?.content?.name || venue?.content?.Name || venue?.name || venue?.content?.SyncName;
const venueScene = primeEvent?.content?.SyncScene;

const details: { label: string; value?: string }[] = [];
try {
  details.push(
    {
      label: translate('label.performanceType'),
      value: mainProductionCategory?.content?.name,
    },
    {
      label: translate('label.place'),
      value: venueScene ? `${venueString}, ${venueScene}` : venueScene,
    },
    {
      label: translate('label.duration'),
      value: duration || primeEvent?.content.SyncEventDuration,
    },
  );
} catch (err) {
  console.error('ProductionDetails: Failed to read production details');
  console.error(err);
}
const ticketList = primeEvent?.content.SyncPricing.filter((pricing) => {
  return !!pricing.PriceZoneA && !!pricing.PriceZoneB && !!pricing.PriceZoneC;
});
const ticketHasPrice = (ticket: TicketProps) => {
  return !!ticket.PriceZoneA && !!ticket.PriceZoneB && !!ticket.PriceZoneC;
};
const formatPrices = (prices: number[]) => {
  return prices.reduce((accumulator: number[], currentPrice) => {
    const priceIndex = accumulator.indexOf(currentPrice);

    if (priceIndex < 0 && currentPrice > 0) {
      accumulator.push(currentPrice);
    }

    return accumulator;
  }, []);
};

const isMounted = ref(false);
const width = ref(1920);

const ticketCarouselLength = computed(() => {
  switch (true) {
    case width.value < 640:
      return 1.3;
    case width.value < 1024:
      return 2.3;
    case width.value < 1280:
      return 3.3;
    default:
      return 4.3;
  }
});

onMounted(() => {
  isMounted.value = true;

  nextTick(() => {
    width.value = window.innerWidth;

    window.addEventListener('resize', () => {
      width.value = window.innerWidth;
    });
  });
});

// Favourites

const auth = useAuth();
const isLoggedIn = computed(() => auth.user.value.id);
const favouritesState = useFavourites();

const removeFavourite = async (SyncId: string) => {
  await customerIoAPI.removeEventFromFavourites(SyncId);
};

const addFavourite = async (SyncId: string) => {
  await customerIoAPI.addEventToFavourites(SyncId);
};

console.log(eventList);
</script>

<style scoped>
.ticket {
  border-radius: 10px 0 0 10px;

  &:before,
  &:after {
    width: 12px;
    height: 50%;

    position: absolute;
    left: 0;

    border-left: 1px dashed rgba(255, 255, 255, 0.7);
    border-radius: 10px 0 0 10px;
    content: '';
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
}

.speech-bubble::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px 8px 0;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.word-break {
  word-break: normal;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}
</style>
