<template>
  <div class="bg-dark text-white min-h-screen pt-32 md:pt-72 pb-16">
    <div>
      <div class="container mb-12 lg:mb-18">
        <h1 v-if="title" class="text-6xl md:text-7xl font-secondary">{{ title }}</h1>
        <div v-if="text" class="text-grey text-lg lg:text-xl">{{ text }}</div>
      </div>
      <div class="bg-grey-dark">
        <MxBreadcrumbs class="container !py-2" align="left" />
      </div>

      <div class="container">
        <div v-if="isSignedIn === null" class="flex items-center justify-center">
          <Icon class="text-primary text-8xl" name="Spinner" />
        </div>
        <div v-else-if="isSignedIn === false" class="pt-8">
          <MxButton :href="auth.logInUrl.value" size="large">
            {{ translate('label.logIn') }}
            <Icon class="text-xl" name="ArrowRight" width="1.5" />
          </MxButton>
        </div>
        <div v-else class="flex flex-col space-y-12 mt-12">
          <div class="flex flex-col space-y-8">
            <h2 class="text-5xl md:text-6xl font-secondary pb-2 border-b-1 border-b-white/25">
              {{ translate('label.purchasesAndFavourites') }}
            </h2>
            <div v-if="loadingOrders" class="flex items-center justify-center" aria-hidden="true">
              <Icon class="text-primary text-8xl" name="Spinner" />
            </div>
            <div v-else-if="combinedEvents.length === 0">
              {{ translate('label.noContent') }}
            </div>
          </div>

          <section class="flex flex-col h-full gap-8">
            <div v-for="(date, index) in uniqueDates" class="h-full">
              <h3 class="text-5xl font-secondary border-b-1 border-b-white/25 pb-1 mb-4">
                {{
                  formatDate(date, {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                  })
                }}
                ({{
                  combinedEvents.filter(
                    (event) => event.content.SyncEventStartTime.slice(0, 10) === date,
                  ).length
                }})
              </h3>
              <div
                :ref="
                  (el) => {
                    scrollContainers[index] = el;
                  }
                "
                class="flex flex-row overflow-x-scroll scrollbar-hide"
              >
                <div v-for="event in combinedEvents" class="w-content">
                  <div
                    v-if="event.content.SyncEventStartTime.slice(0, 10) === date"
                    class="mr-4 h-full"
                  >
                    <div
                      class="flex flex-col collection-item relative w-[250px] 2xl:min-w-[280px] h-full"
                    >
                      <div v-if="event.isPurchased" class="bg-white text-black px-2 font-medium">
                        {{ translate('label.boughtEvent') }}
                      </div>
                      <div
                        v-if="!event.isPurchased && event.isFavourite"
                        class="bg-primary text-black px-2 font-medium flex items-center gap-1"
                      >
                        {{ translate('label.favourite') }}
                        <Icon class="text-black text-xs" name="Heart" />
                      </div>
                      <MxAssetRenderer
                        v-if="event.content?.SyncProduction[0]?.content?.SyncImages[0]?.filename"
                        class="media bg-light-accent overflow-hidden h-[150px] w-[250] 2xl:w-[280px]"
                        :asset="event.content.SyncProduction[0].content.SyncImages[0]"
                        :modifiers="{ gravity: 'subject' }"
                        fit="fill"
                        :aspect-ratio="'3:2'"
                        :video-config="videoConfig"
                      />
                      <MxAssetRenderer
                        v-else-if="event.content?.SyncProduction[0]?.content?.thumbnail.filename"
                        class="media bg-light-accent overflow-hidden h-[150px] w-[250] 2xl:w-[280px]"
                        :asset="event.content.SyncProduction[0].content.thumbnail"
                        :modifiers="{ gravity: 'subject' }"
                        fit="fill"
                        :aspect-ratio="'3:2'"
                        :video-config="videoConfig"
                      />
                      <div class="flex flex-col flex-grow pb-3 bg-grey-dark p-4">
                        <h3
                          class="text-2xl relative z-10 font-secondary word-break text-white mb-1"
                          :lang="language"
                        >
                          {{ event.name }}
                        </h3>

                        <div
                          class="flex flex-col mb-2 xl:mb-1 py-3 gap-2 text-white border-t border-t-solid border-t-white/25 sm:text-xl"
                        >
                          <span
                            v-if="event.content.SyncEventStartTime"
                            class="flex items-center gap-x-2 text-base"
                          >
                            <Icon class="text-primary" name="Calendar" />
                            {{
                              formatDate(addTimeZoneToSBDate(event.content.SyncEventStartTime), {
                                weekday: 'long',
                                day: 'numeric',
                                month: 'long',
                              })
                            }}
                          </span>
                          <span class="flex items-center text-base">
                            <Icon class="text-primary mr-2" name="Clock" />
                            <span v-if="language === 'no'" class="mr-1">Kl.</span>
                            {{
                              formatDate(addTimeZoneToSBDate(event.content.SyncEventStartTime), {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })
                            }}
                            -
                            {{
                              formatDate(addTimeZoneToSBDate(event.content.SyncEventEndTime), {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })
                            }}
                          </span>
                          <span
                            v-if="event.content.SyncScene"
                            class="flex items-center gap-x-2 text-base break-words"
                          >
                            <Icon class="text-primary" name="LocationMarker" />
                            {{ event.content.SyncScene }}
                          </span>
                        </div>
                        <div class="flex flex-row mt-auto w-full justify-between gap-2">
                          <MxButton
                            v-if="event.isPurchased"
                            size="small"
                            target="_blank"
                            :href="event.orderLink"
                            class="group w-fit !mt-2 flex-grow text-base text-medium bg-white border-white !flex !items-center !mt-auto !gap-x-2 !hover-text-primary"
                          >
                            <span class="pb-[1px]">{{ translate('label.seeTicket') }}</span>
                            <Icon class="text-xl" name="ArrowRight" width="1.2" />
                          </MxButton>
                          <MxButton
                            v-else-if="event.content.SyncFreeEvent === true"
                            size="small"
                            target="_blank"
                            class="group w-fit !mt-2 flex-grow text-base text-medium !flex !items-center !mt-auto !gap-x-2 !hover-text-primary"
                          >
                            <span v-if="event.content.SyncFreeEvent === true" class="pb-[1px]">{{
                              translate('action.free')
                            }}</span>
                          </MxButton>
                          <MxButton
                            v-else-if="event.content.SyncFreeEvent !== true"
                            size="small"
                            target="_blank"
                            :href="event.content?.SyncBookingLink?.url"
                            class="group w-fit !mt-2 flex-grow text-base text-medium !flex !items-center !mt-auto !gap-x-2 !hover-text-primary"
                          >
                            <span class="pb-[1px]">{{ translate('action.purchase') }}</span>
                            <Icon class="text-xl" name="ArrowRight" width="1.2" />
                          </MxButton>

                          <div>
                            <MxButton
                              v-if="favouritesState.favourites.includes(event.content.SyncId)"
                              class="group text-3xl h-fit !py-2 !px-2 !bg-transparent !hover:bg-primary z-10"
                              :class="{
                                'cursor-wait': favouritesState.isLoading,
                              }"
                              size="small"
                              :disabled="favouritesState.isLoading"
                              @click="() => removeFavourite(event.content.SyncId)"
                            >
                              <Icon name="Heart" class="text-primary group-hover:text-grey-dark" />
                            </MxButton>
                            <MxButton
                              v-else
                              class="text-3xl h-fit !py-2 !px-2 !bg-gray/20 !border-gray/40 !hover:bg-transparent z-10"
                              :class="{
                                'cursor-wait': favouritesState.isLoading,
                              }"
                              size="small"
                              :disabled="favouritesState.isLoading"
                              @click="() => addFavourite(event.content.SyncId)"
                            >
                              <Icon name="HeartOutline" />
                            </MxButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ScrollIndicator :container="scrollContainers[index]" />
            </div>
          </section>

          <div class="grid lg:grid-cols-3 grid-gap-14 lg:grid-gap-4">
            <div class="flex flex-col space-y-6">
              <h2 class="text-5xl font-secondary pb-2 border-b-1 border-b-white/25">
                {{ translate('heading.myInformation') }}
              </h2>
              <Shade position="topLeft" size="regular">
                <div class="bg-white text-dark py-2 px-4 lg:py-4 lg:px-6">
                  <h3 class="text-2xl font-semibold mb-2">
                    {{ translate('label.profileDetails') }}
                  </h3>
                  <div class="flex flex-col space-y-8 md:space-y-0 justify-between items-start">
                    <div
                      v-if="loadingCustomer"
                      class="flex items-center justify-center"
                      aria-hidden="true"
                    >
                      <Icon class="text-primary text-8xl" name="Spinner" />
                    </div>
                    <div class="flex flex-col gap-2 mb-4">
                      <div class="text-xl !leading-6">
                        <h4 class="font-bold">{{ translate('label.email') }}:</h4>
                        <p v-if="customerInfo" class="">{{ customerInfo.Email }}</p>
                        <p v-else class="">{{ auth.user.value.email }}</p>
                      </div>
                      <div v-if="customerInfo" class="text-xl !leading-6">
                        <h4 class="font-bold">{{ translate('label.mobilePhone') }}:</h4>
                        <p class0="">{{ customerInfo.Mobile }}</p>
                      </div>
                    </div>

                    <MxButton
                      size="large"
                      class="text-lg font-medium !mt-2"
                      :href="auth.profileUrl"
                    >
                      {{ translate('label.editDetails') }}
                      <Icon class="text-xl" name="ArrowRight" width="1.5" />
                    </MxButton>
                  </div>
                </div>
              </Shade>
            </div>

            <div class="flex flex-col space-y-6">
              <h2 class="text-5xl font-secondary pb-2 border-b-1 border-b-white/25">
                {{ translate('heading.doYouHaveAnyQuestions') }}
              </h2>
              <Shade position="topLeft" size="regular">
                <div class="bg-white text-dark py-2 px-4 lg:py-4 lg:px-6 flex flex-col items-start">
                  <h3 class="text-2xl font-semibold">{{ translate('label.doYouNeedHelp') }}</h3>
                  <p class="text-xl !leading-6">
                    {{ translate('label.ifYouNeedHelpWithAnOrder') }}
                  </p>
                  <div class="text-center mt-4">
                    <MxButton size="large" class="text-lg font-medium !mt-2">
                      {{ translate('label.contactUsHere') }}
                      <Icon class="text-xl" name="ArrowRight" width="1.5" />
                    </MxButton>
                  </div>
                </div>
              </Shade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-editable="blok">
    <StoryblokComponent v-for="blokItem in blok.body" :key="blokItem._uid" :blok="blokItem" />
  </div>
</template>

<script setup lang="ts">
import { watch, toRaw } from 'vue';
import { useAuth } from '~/composables/useAuth';
import { tixlyApi, type TixlyEventOrder, type TixlyCustomerInfo } from '~/composables/tixlyApi';
import { useFavourites } from '~/composables/useFavourites';
import { STORY_RELATIONS } from '~/composables/relations';
import { useTranslation } from '~/composables/useTranslation';
import type { EventStory } from '~/types/Production';
import { customerIoAPI } from '~/composables/customerIoApi';

const scrollContainers = ref<(Element | ComponentPublicInstance | null)[]>([]);

const translate = useTranslation();
const language = useState<string>('language');

const { blok } = defineProps<{
  blok: any;
}>();
const { title, text } = blok;

const runtimeConfig = useRuntimeConfig();

declare type EventStoryWithOrderLink = EventStory & {
  orderLink?: string;
};

const videoConfig = {
  wrapperClass: '!absolute top-0 left-0 media lg:!aspect-auto h-full min-w-full',
  playerClass: '!h-full w-full z-0 object-cover object-center',
  autoplay: true,
  muted: true,
  loop: true,
  hideControls: true,
  loadOnVisible: true,
  nativePlayer: true, // use native html video player with auto-adjusting source
  transformations: 'eo_7', // note: limit length to 7 seconds
};

// Profile info
const loadingCustomer = ref(true);
const customerInfo = ref<TixlyCustomerInfo | undefined>();

const fetchUserInfo = async (email: string) => {
  loadingCustomer.value = true;
  customerInfo.value = await tixlyApi.getCustomerInfo(email);
  loadingCustomer.value = false;
};

// Tixly purchased events
const orders = ref<TixlyEventOrder[]>([]);
const loadingOrders = ref(true);
const purchasedEventsData = ref<EventStoryWithOrderLink[]>([]);
const fetchOrders = async () => {
  if (!customerInfo.value?.CustomerId) {
    loadingOrders.value = false;
    console.warn('No tixly customerId found for user email');
    return;
  }
  loadingOrders.value = true;
  orders.value = await tixlyApi.getMyOrders(customerInfo.value?.CustomerId);
};
const purchasedEventIds = computed(() => orders.value.map((order) => String(order.eventId)));

// When orders are loaded, load events data for those
watch([orders], async () => {
  loadingOrders.value = true;
  const purchasedEventsResponse = await fetchEventsData(purchasedEventIds.value);

  purchasedEventsData.value = purchasedEventsResponse.map((purchasedEvent) => {
    const orderItem = orders.value.find((o) => String(o.eventId) === purchasedEvent.content.SyncId);
    const languageCode = String(language.value) as 'en' | 'no';
    const orderLink = orderItem?.links[languageCode];
    return {
      ...purchasedEvent,
      orderLink,
    };
  });
  loadingOrders.value = false;
});

const getPurchasedLinkForEventId = (eventId: string) => {
  const purchasedEvent = purchasedEventsData.value.find((e) => e.content.SyncId === eventId);
  if (!purchasedEvent) {
    return;
  }
  return purchasedEvent.orderLink;
};

// Storyblok Events data

const fetchEventsData = async (eventIds: string[]): Promise<EventStory[]> => {
  const { storyBlokApiKey, publishState } = runtimeConfig.public;
  if (!eventIds.length) {
    return [];
  }

  const params: any = {
    version: publishState as 'draft' | 'published',
    token: storyBlokApiKey,
    resolve_relations: STORY_RELATIONS,
    starts_with: 'data/arrangement',
    per_page: 100,
    filter_query: {
      SyncId: {
        in: eventIds.join(','),
      },
    },
  };

  try {
    const response = await useStoryblokApi().get(`cdn/stories`, params);

    return response.data.stories;
  } catch (error) {
    console.warn('error while fetching the article from a category', error);

    return [];
  }
};

// auth
const auth = useAuth();
const authUserIdComputed = computed(() => auth.user.value?.id);
const isSignedIn = ref<boolean | null>(null);

watch(
  [authUserIdComputed],
  async ([userId]) => {
    if (userId === null) {
      return; // null is initial value, nothing changed yet
    }
    if (userId === false) {
      isSignedIn.value = false;
      return;
    }
    isSignedIn.value = true;
    // note: needs to load user info (customerId) before fetchOrders
    if (auth.user.value.email) {
      await fetchUserInfo(auth.user.value.email);
    }
    fetchOrders();
  },
  {
    immediate: true,
  },
);

// Favourites functionality
const favouritesState = useFavourites();

const addFavourite = async (SyncId: string) => {
  await customerIoAPI.addEventToFavourites(SyncId);
};

// Favourites
const favourites = useFavourites();
const loadingFavourites = ref(true);
const favouriteEventsData = ref<EventStoryWithOrderLink[]>([]);
const favouritesComputed = computed(() => favourites.value.favourites);
const uniqueDates = ref<string[]>([]);

watch(
  [favouritesComputed],
  async () => {
    loadingFavourites.value = true;
    const data = await fetchEventsData(favourites.value.favourites);

    const mappedData = data.map((event) => {
      return {
        ...event,
        orderLink: getPurchasedLinkForEventId(event.content.SyncId),
      };
    });

    // Sort by SyncEventStartTime
    favouriteEventsData.value = mappedData.sort((a, b) => {
      const dateA = new Date(a.content.SyncEventStartTime);
      const dateB = new Date(b.content.SyncEventStartTime);
      return dateA - dateB;
    });

    loadingFavourites.value = false;
  },
  {
    immediate: true,
  },
);

const removeFavourite = async (SyncId: string) => {
  await customerIoAPI.removeEventFromFavourites(SyncId);
};

const combinedEvents = computed(() => {
  const favorites = favouriteEventsData.value ? toRaw(favouriteEventsData.value) : [];
  const purchased = purchasedEventsData.value ? toRaw(purchasedEventsData.value) : [];

  const eventsMap = new Map();

  // Add purchased events first
  purchased.forEach((event) => {
    eventsMap.set(event.uuid, {
      ...event,
      isPurchased: true,
      isFavourite: false,
    });
  });
  // Add or update with favorite events
  favorites.forEach((event) => {
    const existingEvent = eventsMap.get(event.uuid);
    if (existingEvent) {
      eventsMap.set(event.uuid, {
        ...existingEvent,
        isFavourite: true,
      });
    } else {
      eventsMap.set(event.uuid, {
        ...event,
        isPurchased: false,
        isFavourite: true,
      });
    }
  });

  return Array.from(eventsMap.values());
});

watch(
  combinedEvents,
  (newCombined) => {
    const sortedEvents = [...newCombined].sort((a, b) => {
      const dateA = new Date(a.content.SyncEventStartTime);
      const dateB = new Date(b.content.SyncEventStartTime);
      return dateA - dateB;
    });

    uniqueDates.value = [
      ...new Set(
        sortedEvents.map((event) => {
          return event.content.SyncEventStartTime.slice(0, 10);
        }),
      ),
    ];
  },
  { deep: true },
);
</script>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.word-break {
  word-break: normal;
  hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}
</style>
